import React from "react";
import "react-vis/dist/style.css";
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ResponsiveContainer,
  CartesianGrid,
  ReferenceLine
} from "recharts";
import "./VitalsLineChart.scss";
import moment from "moment";
import DataToPretty from '../../functions/dataToPretty';

const VitalsLineChart = ({
  areaColor,
  areaColor2,
  data,
  dataLabel,
  dataLabel2,
  startDate,
  endDate,
  dataMinOffset,
  dataMaxOffset,
  dataMin,
  dataMax,
  title,
  thresholdMax,
  thresholdMin,
  SystolicMax,
  SystolicMin,
  DiastolicMin,
  DiastolicMax,
  id                // (optional) overrides id attribute (defaults to props.dataLabel)
}) => {
  if (!dataMinOffset) dataMinOffset = 20;
  if (!dataMaxOffset) dataMaxOffset = 20;

  const dataSubset = calculateDataSubset(data, startDate, endDate);

  if (dataSubset.length < 1)
    return (
      <div className="vitals-line-chart__container" id={id ? id : dataLabel}>
        {title ? <h2>{title}</h2> : <h2>{DataToPretty[dataLabel]}</h2>}
        <p>No data</p>
      </div>
    );
  

  let dataRangeinDays = moment(dataSubset[dataSubset.length - 1].name).diff(
    dataSubset[0].name,
    "days"
  );

  return (
    <div className="vitals-line-chart__container" id={id ? id : dataLabel}>
      {title ? <h2>{title}</h2> : <h2>{DataToPretty[dataLabel]}</h2>}
      <ResponsiveContainer width="100%" height="110%">
        <LineChart
          width={500}
          height={250}
          data={dataSubset}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}   
        >
          <CartesianGrid 
            vertical={false}
          /> 
          <XAxis
            type="number"
            domain={["dataMin", "dataMax"]}
            axisLine={false}
            tickLine={false}
            dataKey="date"
            scale="auto"
            tick={<CustomXTick dataRangeinDays={dataRangeinDays} />}
            height={50}
          />
          <YAxis
            domain=
            { 
              dataMin && dataMax ? ([
                dataMin,
                dataMax
              ])  : dataMin ? ([
                dataMin,
                `dataMax + ${dataMaxOffset}`
              ]) : dataMax ? ([
                `dataMin - ${dataMinOffset}`,
                dataMax
              ]) : ([
                `dataMin - ${dataMinOffset}`,
                `dataMax + ${dataMaxOffset}`
              ])
            }
            axisLine={false}
            tickLine={false}
            dataKey={"value"}
            scale="auto"
          />

          <Tooltip
            content={CustomTooltip}
            dataLabel={dataLabel}
            dataLabel2={dataLabel2}
          />

          <Line
            dataKey={"value"}
            stroke={areaColor}
            type="monotone"
            strokeWidth={2}
            isAnimationActive={false}
          />

          {thresholdMax ? ( 
              <ReferenceLine
                  y={thresholdMax}
                  label="Max" 
                  stroke="red" 
                  strokeDasharray="3 3" 
                />
           ) : null}

          {thresholdMin ? ( 
              <ReferenceLine
                  y={thresholdMin}
                  label="Min" 
                  stroke="red" 
                  strokeDasharray="3 3" 
              />
           ) : null}

           {SystolicMax ? ( 
              <ReferenceLine
                  y={SystolicMax}
                  label="Systolic Max" 
                  stroke="red" 
                  strokeDasharray="3 3" 
              />
           ) : null}

           {SystolicMin ? ( 
              <ReferenceLine
                  y={SystolicMin}
                  label="Systolic Min" 
                  stroke="red" 
                  strokeDasharray="3 3" 
              />
            ) : null}

           {DiastolicMax ? ( 
              <ReferenceLine
                  y={DiastolicMax}
                  label="Diastolic Max" 
                  stroke="red" 
                  strokeDasharray="3 3" 
              />
           ) : null}

           {DiastolicMin ? ( 
              <ReferenceLine
                  y={DiastolicMin}
                  label="Diastolic Min" 
                  stroke="red" 
                  strokeDasharray="3 3" 
              />
              ) : null}

          {dataLabel2 ? (
            <Line
              dataKey={"value2"}
              stroke={areaColor2}
              type="monotone"
              strokeWidth={2}
              isAnimationActive={false}
            />
          ) : null}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

const CustomTooltip = ({ payload, dataLabel, dataLabel2 }) => {
  if (payload === null) return null;
  if (payload[0] === undefined) return null;

  return (
    <div className="vitals-line-chart__tooltip">
      <p>{payload[0].payload.name}</p>
      <div className="vitals-line-chart__tooltip-value-container">
        <p className="vitals-line-chart__tooltip-value">
          {DataToPretty[dataLabel]}: {payload[0].value}
        </p>
        <p className="vitals-line-chart__tooltip-units">{payload[0].payload.units}</p>
      </div>
      {dataLabel2 ? (
        <div className="vitals-line-chart__tooltip-value-container">
          <p className="vitals-line-chart__tooltip-value">
            {DataToPretty[dataLabel2]}: {payload[1].value}
          </p>
          <p className="vitals-line-chart__tooltip-units">
            {payload[0].payload.units}
          </p>
        </div>
      ) : null}
    </div>
  );
};

const calculateDataSubset = (data, startDate, endDate) => {
  return data.filter(reading => !(moment(reading.date).isBefore(startDate) || moment(reading.date).isAfter(endDate)));
}

const CustomXTick = ({ x, y, payload, dataRangeinDays }) => {
  if (dataRangeinDays === 0)
    return (
      <g transform={`translate(${x},${y})`} textAnchor="middle">
        <text x={0} y={0} dy={22} textAnchor="middle" fill="#666">
          {moment(payload.value).format("h:mmA")}
        </text>
        <text
          x={0}
          y={20}
          dy={16}
          textAnchor="middle"
          fill="#666"
          className="vitals-line-chart__x-axis-secondary"
        >
          {moment(payload.value).format("ddd MMM D")}
        </text>
      </g>
    );

  if (dataRangeinDays <= 7)
    return (
      <g transform={`translate(${x},${y})`} textAnchor="middle">
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
          {moment(payload.value).format("ddd")}
        </text>
        <text
          x={0}
          y={20}
          dy={16}
          textAnchor="middle"
          fill="#666"
          className="vitals-line-chart__x-axis-secondary"
        >
          {moment(payload.value).format("MMM D")}
        </text>
      </g>
    );

  if (dataRangeinDays <= 90)
    return (
      <g transform={`translate(${x},${y})`} textAnchor="middle">
        <text x={0} y={20} dy={2} textAnchor="middle" fill="#666">
          {moment(payload.value).format("MMM D")}
        </text>
      </g>
    );

  if (dataRangeinDays <= 365)
    return (
      <g transform={`translate(${x},${y})`} textAnchor="middle">
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
          {moment(payload.value).format("ddd")}
        </text>
        <text
          x={0}
          y={20}
          dy={16}
          textAnchor="middle"
          fill="#666"
          className="vitals-line-chart__x-axis-secondary"
        >
          {moment(payload.value).format("MMM D")}
        </text>
      </g>
    );
};

export default VitalsLineChart;
