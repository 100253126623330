import React, { useState, useEffect } from "react";
import { AutoSizer, Column, Table } from "react-virtualized";
import moment from "moment";
import _ from 'lodash';
import "../CustomerTable/CustomerTable.scss";

const AlertLog = ({ data, update }) => {

//set state from data prop
const [rows, setRows] = useState(data);

//when data prop changes render the rows 
useEffect(() => {
 const newRows = () => {
   const newData =  _.map(data, (o) => {
      return {...o, timestamp:moment.utc(o.timestamp).local().format('MM-DD-YYYY LT')}
    })
     setRows(_.reverse(newData));
    //  console.log(newData)
  }
 newRows();
} 
, [data]);


//build the virtualized table 
return (
    <div className="customer-table__container">
      <div className="customer-table__header">
        <h1>Alert Log</h1>
      </div>
      <div className="customer-table__auto-sizer">
        <AutoSizer>
          {({ height, width }) => (
            <Table
              headerHeight={56}
              height={height}
              rowCount={rows.length}
              rowGetter={({ index }) => rows[index]}
              rowHeight={56}
              width={width}
            >
              <Column 
                label="Date" 
                dataKey="timestamp" 
                width={200} 
              />
              <Column
                label="Flag"
                dataKey="flag"
                width={200}
              />
              <Column 
                label="Measured At" 
                dataKey="measuredAt" 
                width={200} 
              />
              <Column
                label="Limit Over Threshold"
                dataKey="limitOver"
                width={200}
              />
              <Column 
                label="Threshold Type" 
                dataKey="vitalName" 
                width={200} 
              />
              <Column
                label="Is Addressed?"
                dataKey="addressed"
                width={200}
              />
              <Column 
                label="Addressed By" 
                dataKey="addressedBy" 
                width={200} 
              />
               <Column 
                label="Addressed Time" 
                dataKey="addressedTime" 
                width={200} 
              />

            </Table>
          )}
        </AutoSizer>
      </div>
    </div>
  );


};


export default AlertLog;
