import React from 'react';
import './styles.scss';
import Header from "./components/Header/Header";
import Dashboard from "./containers/Dashboard/Dashboard";
import { withAuthenticator } from "aws-amplify-react";
import { SignIn, VerifyContact } from 'aws-amplify-react/dist/Auth';
import ForgotPassword from 'aws-amplify-react/dist/Auth/ForgotPassword';
import ConfirmSignIn from 'aws-amplify-react/dist/Auth/ConfirmSignIn';
import { Button } from './LoginTheme'
import { I18n } from  'aws-amplify'
import RequireNewPassword from 'aws-amplify-react/dist/Auth/RequireNewPassword';
import IdleTimer from 'react-idle-timer'
import { Auth } from "aws-amplify";

const App = () => (
    <div>
        {/*Logout Timer after 30 seconds of inactivity */}
        <IdleTimer 
        timeout={1800000}
        onIdle={async e => {
            console.log("time left on idle")
            await Auth.signOut();
            await window.location.reload(true)       
        }}
        />
        <Header />
        <Dashboard scrollStepInPx="50" delayInMs="16.66" />
        {/* <Footer /> */}
    </div>
);

// Theme of Authenticator component 
const Theme = {
    button: Button.button,
    formContainer: {color:"#fff", height:"25%"},
    container: { backgroundColor:"#EEF0F6" },
    backgroundColor: {backgroundColor:"#fff"},
    a: { color: 'rgb(75, 203, 243)' }
}

// replace screen label on Authenticator component
const authScreenLabels = {
    en: {
        'Sign in to your account': 'ECG Health Interface'
        
    }
};


I18n.setLanguage('en');
I18n.putVocabularies(authScreenLabels);

export default withAuthenticator(App, false, [
    <SignIn />,
    <ForgotPassword />,
    <ConfirmSignIn />,
    <VerifyContact />,
    <RequireNewPassword />
],
null, Theme)

