const DataToPretty = {
  BloodPressure: "Blood Pressure",
  BodyTemperature: "Temperature",
  BPM: "Heart Rate",
  HeartRate: 'Heart Rate',
  Diastolic: "Diastolic",
  Glucose: "Glucose",
  Oxygen: "Oxygen",
  Systolic: "Systolic",
  Weight: "Weight",
};

export default DataToPretty;