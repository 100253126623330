import React from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import './CustomerDropdown.scss';


const CustomerDropdown = ({ customers, onChange, disabled, currentCustomerIndex, group }) => {
  const MRoptions = customers.map((customer, index) => ({ value: index, text: `${customer.customer_name}, ${customer.date_of_birth}, ${customer.MRNum}` }));
  const options = customers.map((customer, index) => ({ value: index, text: `${customer.customer_name}, ${customer.date_of_birth}` }));


  return (
    <div className="customer-dropdown__container">
      <Dropdown
        disabled={disabled}
        options={group === 'ZiaHealthcare' ? MRoptions : options}
        fluid
        search
        selection
        value={currentCustomerIndex !== null ? options[currentCustomerIndex].value : ''}
        placeholder={(
          <p>
            <Icon name="search" />
            Search Patient
          </p>
        )}
        onChange={(e, { value }) => {
          onChange(value);
        }}
      />
    </div>
  );
};

export default CustomerDropdown;