import _ from 'lodash';
import { Auth } from 'aws-amplify';
import axios from 'axios';

import formatVitalsData from './formatVitalsData';

/**
  * populates customer object via xmit_id
  * @param {Object} user - customer w/ valid .xmid_id attribute
  * @returns {Object} complete customer object
  */
 const getVitalsDataForCustomer = async user => {
  const headerData =  await Auth.currentSession()
  const options = {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      "Authorization":  headerData.idToken.jwtToken
    },
    body: JSON.stringify({
      account: user.xmit_id
    })
  };

 // console.log(headerData.idToken.jwtToken)

  return fetch (
    "https://1ig95uydq7.execute-api.us-east-1.amazonaws.com/prod/",
    options
  ).then(response => {
    if (!response.ok) {
      return response.text().then(result => Promise.reject(new Error(result)));
    }

    return response.json().then( async data => {

        // format vitals data
        let formattedData = formatVitalsData(data, user.updated_at);

        // get thresholds
        const getThresholdArray = async () => {
          const formattedThresholdData = [];
          try {
            const thresholdData = await axios.post("https://o6dy65bp92.execute-api.us-east-1.amazonaws.com/prod", {
              "xmitId":user.xmit_id
            })
            //create and array of thresholds with metadata 
            thresholdData.data.Items.map(data =>
              formattedThresholdData.push({ 
                createdAt: data.createdAt.S,
                orbId: data.orbId.S,
                localOwner:  data.localOwner ? data.localOwner.S : null,
                maximum: data.maximum ? data.maximum.N : null,
                minimum: data.minimum ? data.minimum.N : null,
                thresholdCarePlanId: data.thresholdCarePlanId ? data.thresholdCarePlanId.S : null,
                vitalName: data.vitalName ? data.vitalName.S : null ,
                xmitId: data.xmitId ? data.xmitId.S : null
              }) 
              )
          }
          catch(err){
            console.log(err)
          }
          return formattedThresholdData;
        }

       
        //create a thresholds object for each customer
        const getThresholdObject = async () => {
          const thresholds = await getThresholdArray()
          const thresholdsObject = {
           Oxygen:  _.filter(thresholds, {"vitalName": "Oxygen" })[0],
           BPM:  _.filter(thresholds, {"vitalName": "BPM" })[0],
           Systolic:   _.filter(thresholds, {"vitalName": "Systolic" })[0],
           Diastolic:  _.filter(thresholds, {"vitalName": "Diastolic" })[0],
           Weight:  _.filter(thresholds, {"vitalName": "Weight" })[0],
           Glucose:  _.filter(thresholds, {"vitalName": "Glucose" })[0],
           Temperature:  _.filter(thresholds, {"vitalName": "Temperature" })[0]
        }

        return thresholdsObject;
      }

      //get alerts data 
      const getAlerts = async () => {
        try {
          const thresholdAlerts = await axios.post("https://rwgi3alw14.execute-api.us-east-1.amazonaws.com/prod/getalerts",{
            "xmit":user.xmit_id
        })

        return thresholdAlerts.data;
          }
        catch(err){
          console.log(err)
        }
      }

        // return complete customer object
        return {
          customer_name: user.customer_name,
          lead_id: user.lead_id,
          xmit_id: user.xmit_id,
          date_of_birth: user.date_of_birth,
          customer_phone: user.customer_phone,
          doctor_name: user.doctor_name,
          data: formattedData,
          thresholdDataArray: await getThresholdArray(),
          thresholdDataObject: await getThresholdObject(),
          alerts: await getAlerts(),
          MRNum: user.MRNum,
          activation_date: user.updated_at,
          office: user.office
        }
      }
    );
  });
};

export default getVitalsDataForCustomer;