import React, { useState } from 'react';
import { DateRangePicker } from 'react-dates';
import { Button } from 'semantic-ui-react';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './VitalsDatePicker.scss';

const VitalsDatePicker = ({ customer, setDateRange, startDate, endDate, disabled }) => {
  const [focusedInput, setFocusedInput] = useState(null);

  const DatePresets = () => (
    <div className='vitals-date-picker__date-presets'>
      <Button onClick={() => setPresetRange(7)}>Past 7 Days</Button>
      <Button onClick={() => setPresetRange(14)}>Past 14 Days</Button>
      <Button onClick={() => setPresetRange(30)}>Past 30 Days</Button>
      <Button onClick={() => setPresetRange(90)}>Past 90 Days</Button>
    </div>
  );

  const setPresetRange = (days) => {
    setDateRange({ newStartDate: moment().subtract(days, 'days').startOf('day'), newEndDate: moment().endOf('day')})
  }

  return (
    <div className="date-range-picker__container" style={{float: 'right'}}>
      <DateRangePicker
        disabled={disabled}
        anchorDirection='right'
        isOutsideRange={(data) => data > moment().endOf('day')}
        startDate={startDate} // momentPropTypes.momentObj or null,
        startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
        endDate={endDate} // momentPropTypes.momentObj or null,
        endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
        onDatesChange={({ startDate, endDate }) => setDateRange({ newStartDate: startDate, newEndDate: endDate})}
        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={newFocusedInput => { setFocusedInput(newFocusedInput); }} // PropTypes.func.isRequired,
        isDayHighlighted={(date) => customer.data ? customer.data.Dates.includes(date.format('MM/DD/YYYY')) : false}
        hideKeyboardShortcutsPanel
        displayFormat='MMM D YYYY'
        //renderCalendarInfo={DatePresets}
      />
    </div>
  );
};

export default VitalsDatePicker;