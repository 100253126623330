import React from "react";
import ReactDOM from "react-dom";
import "./styles.scss";
import App from "./App";
import getApiInfo from "./ApiData";
import { Auth } from "aws-amplify";

// Font Awesome Imports
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faComment,
  faSignInAlt,
  faSignOutAlt,
  faArrowCircleUp
} from "@fortawesome/free-solid-svg-icons";

// AWS Amplify Imports
import Amplify from "aws-amplify";
import aws_exports from "./aws-exports";
// const LogRocket = require('logrocket/setup')({ sdkServer: 'https://logrocket.ecg-addison.com', ingestServer: 'https://logrocket.ecg-addison.com' });

// LogRocket.init('lifesupportmedical/qa');

Amplify.configure(aws_exports);
Amplify.configure({
  API: {
    graphql_endpoint:
      "https://wzsjr53b35ffrmwy26vl4hpbbu.appsync-api.us-east-1.amazonaws.com/graphql",
    graphql_headers: async () => {
      let header = await Auth.currentSession();
      return {
        "x-api-key": await getApiInfo(header)
      };
    }
  }
});

// Font Awesome Libary
library.add(faComment, faSignInAlt, faSignOutAlt, faArrowCircleUp);

ReactDOM.render(<App />, document.getElementById("root"));
