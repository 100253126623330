import moment from 'moment';
import pushDate from './pushDate';

/**
  * formats vitals data provided by Isaac's API
  * @param {Object} data - customer's vitals data
  * @returns {Object} formattedData - customer's formatted vitals data
  */
 const formatVitalsData = (data, activation_date) => {
  
  // initialize formatted data object
  let formattedData = {
    BloodPressure: [],
    BPM: [],
    Dates: [],
    Glucose: [],
    Oxygen: [],
    AllReadings: [],
    BodyTemperature: [],
    Weight: [],
  };
 console.log("TIMESAMPTMPEWMRFEPFMG",data)
  // iterate through customer's vitals data readings
  data.forEach(reading => {
    if(Boolean(moment.utc(reading.TimeStamp.S).isAfter(moment.utc(activation_date).add(7, 'h')))) {
      // initialize formatted allReadings object
      console.log(moment(activation_date).format('llll'))
      let allReading = {
        date: moment.utc(reading.TimeStamp.S).local().format('MM-DD-YYYY LT'),
        BloodPressure: null,
        HeartRate: null,
        Weight: null,
        Oxygen: null,
        Glucose: null,
        BodyTemperature: null,
      }

      // BPM reading handler
      if ((reading.RawData.S.includes("pulseox") || reading.RawData.S.includes("bloodpressure")) && reading.BPM.S !== "default") {

        // push BPM reading
        formattedData.BPM.push({
          name: moment.utc(reading.TimeStamp.S).local().format("llll"),
          value: reading.BPM.S,
          date: moment.utc(reading.TimeStamp.S).local().valueOf(),
          units: "BPM"
        });

        // add BPM reading to allReading
        allReading.HeartRate = reading.BPM.S;

        // push reading date
        pushDate(formattedData, reading);
      }

      // Oxygen reading handler
      if (reading.RawData.S.includes("pulseox")) {

        // push Oxygen reading
        formattedData.Oxygen.push({
          name: moment.utc(reading.TimeStamp.S).local().format("llll"),
          value: reading.PulseAvg.S,
          date: moment.utc(reading.TimeStamp.S).local().valueOf(),
          units: "%"
        });

        // add Oxygen reading to allReading
        allReading.Oxygen = reading.PulseAvg.S;

        // push reading date
        pushDate(formattedData, reading);
      }

      // BloodPressure reading handler
      if (reading.RawData.S.includes("bloodpressure")) { //
        if (reading.Systolic.S !== "default") {

          // push BloodPressure reading
          formattedData.BloodPressure.push({
            name: moment.utc(reading.TimeStamp.S).local().format("llll"),
            value: reading.Systolic.S,
            value2: reading.Diastolic.S,
            date: moment.utc(reading.TimeStamp.S).local().valueOf(),
            units: "mm/Hg"
          });

          // add BloodPressure to allReading
          allReading.BloodPressure = `${reading.Systolic.S}/${reading.Diastolic.S}`;

          // push reading date
          pushDate(formattedData, reading);
        }
      }

      // Glucose reading handler
      if (reading.RawData.S.includes("glucose")) {

        // push Glucose reading
        formattedData.Glucose.push({
          name: moment.utc(reading.TimeStamp.S).local().format("llll"),
          value: reading.Value.S,
          date: moment.utc(reading.TimeStamp.S).local().valueOf(),
          units: "mg/dl"
        });

        // add Glucose to allReading
        allReading.Glucose = reading.Value.S;

        // push reading date
        pushDate(formattedData, reading);
      }

      // Body Temperature reading handler
      if (reading.RawData.S.includes("thermometer")) {

        // push BodyTemperature reading
        formattedData.BodyTemperature.push({
          name: moment.utc(reading.TimeStamp.S).local().format("llll"),
          value: reading.Value.S,
          date: moment.utc(reading.TimeStamp.S).local().valueOf(),
          units: "°F"
        });

        // add BodyTemperature to allReading
        allReading.BodyTemperature = reading.Value.S;

        // push reading date
        pushDate(formattedData, reading);
      }

      // Weight reading handler
      if (reading.RawData.S.includes("weight")) {

        // push Weight reading
        formattedData.Weight.push({
          name: moment.utc(reading.TimeStamp.S).local().format("llll"),
          value: reading.Value.S,
          date: moment.utc(reading.TimeStamp.S).local().valueOf(),
          units: "lbs"
        });

        // add Weight to allReading
        allReading.Weight = reading.Value.S;

        // push reading date
        pushDate(formattedData, reading);
      }

      // push generic allReading to AllReadings[]
      formattedData.AllReadings.push(allReading);
    } else {
      // if date is before activation date
      // console.log('createdAt', moment(reading.created_at).format('MM/DD/YYYY'));
      // console.log('activation_date', moment(activation_date).format('MM/DD/YYYY'));
      // console.log('activate', moment(reading.created_at).isAfter(moment(activation_date)));
    }
  });

  return formattedData;
};

export default formatVitalsData;