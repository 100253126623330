import React, { useState, useEffect } from "react";
import { Table } from "semantic-ui-react";

const ThresholdsTable = ({ initialState }) => {
  const [tableData, setTableData] = useState({});
  
  //re-render if initial state changes 
  useEffect(() => {
    setTableData(initialState);
  }, [initialState]);

  // return rendered table with thresholds 
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Vital</Table.HeaderCell>
          <Table.HeaderCell>Minimum</Table.HeaderCell>
          <Table.HeaderCell>Maximum</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row error={tableData ? !tableData.Oxygen : false}>
          <Table.Cell>Oxygen</Table.Cell>
          <Table.Cell>
            {tableData && tableData.Oxygen ? tableData.Oxygen.minimum : ""}
          </Table.Cell>
          <Table.Cell>
            {tableData && tableData.Oxygen ? tableData.Oxygen.maximum : ""}
          </Table.Cell>
        </Table.Row>
        <Table.Row error={tableData ? !tableData.BPM : false}>
          <Table.Cell>Heart Rate</Table.Cell>
          <Table.Cell>
            {tableData && tableData.BPM ? tableData.BPM.minimum : ""}
          </Table.Cell>
          <Table.Cell>
            {tableData && tableData.BPM ? tableData.BPM.maximum : ""}
          </Table.Cell>
        </Table.Row>
        <Table.Row error={tableData ? !tableData.Systolic : false}>
          <Table.Cell>Systolic</Table.Cell>
          <Table.Cell>
            {tableData && tableData.Systolic ? tableData.Systolic.minimum : ""}
          </Table.Cell>
          <Table.Cell>
            {tableData && tableData.Systolic ? tableData.Systolic.maximum : ""}
          </Table.Cell>
        </Table.Row>
        <Table.Row error={tableData ? !tableData.Diastolic : false}>
          <Table.Cell>Diastolic</Table.Cell>
          <Table.Cell>
            {tableData && tableData.Diastolic
              ? tableData.Diastolic.minimum
              : ""}
          </Table.Cell>
          <Table.Cell>
            {tableData && tableData.Diastolic
              ? tableData.Diastolic.maximum
              : ""}
          </Table.Cell>
        </Table.Row>
        <Table.Row error={tableData ? !tableData.Weight : false}>
          <Table.Cell>Weight</Table.Cell>
          <Table.Cell>
            {tableData && tableData.Weight ? tableData.Weight.minimum : ""}
          </Table.Cell>
          <Table.Cell>
            {tableData && tableData.Weight ? tableData.Weight.maximum : ""}
          </Table.Cell>
        </Table.Row>
        <Table.Row error={tableData ? !tableData.Glucose : false}>
          <Table.Cell>Glucose</Table.Cell>
          <Table.Cell>
            {tableData && tableData.Glucose ? tableData.Glucose.minimum : ""}
          </Table.Cell>
          <Table.Cell>
            {tableData && tableData.Glucose ? tableData.Glucose.maximum : ""}
          </Table.Cell>
        </Table.Row>
        <Table.Row error={tableData ? !tableData.Temperature : false}>
          <Table.Cell>Body Temperature</Table.Cell>
          <Table.Cell>
            {tableData && tableData.Temperature
              ? tableData.Temperature.minimum
              : ""}
          </Table.Cell>
          <Table.Cell>
            {tableData && tableData.Temperature
              ? tableData.Temperature.maximum
              : ""}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default ThresholdsTable;
