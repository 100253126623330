import { API, graphqlOperation } from 'aws-amplify';

const getOfficesByAccount = async (group) => {
  return API.graphql(graphqlOperation(`query listOfficeIDS {
    listOfficeIDS(filter: {
      GroupName:{
        eq:"${group}"
      }
      activeOffice:{
          eq:true
        }  
      }, limit: 100)
      {
      items {
        officeID
        officeName
        description
      }
    } 
  }
  `));
};

export default getOfficesByAccount;