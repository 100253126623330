import axios from 'axios';

/**
  * get alerts for thresholds that have been triggered
  * @param {Object} group - logged in group
  * @returns {thresholdLimit} returns threshold limits set for that organization 
  */
const getMasterThresholdLimits = async (GroupID) => (
  axios({
    method: 'post',
    url: 'https://rwgi3alw14.execute-api.us-east-1.amazonaws.com/prod/getmasterthresholdlimits',
    data: {
      GroupID,
    },
  })
    .then(({ data }) => data)
    .catch((error) => { throw error; })
);

export default getMasterThresholdLimits;