import { Auth } from 'aws-amplify';

/**
  * populates customers[] with ONLY customer NAMES for given group
  * @param {String} group - customer group
  */
 const getCustomersFromGroup = async group => {
  const headerData =  await Auth.currentSession()
  const options = {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      "Authorization":  headerData.idToken.jwtToken
    },
    body: JSON.stringify({
      group
    })
  };

  // fetch customers
  return fetch(
    "https://7gbihi5tci.execute-api.us-east-1.amazonaws.com/prod/",
    options
  ).then(response => {
    if (!response.ok) {
      return response.text().then(result => Promise.reject(new Error(result)));
    }

    return response.json().then(data => {
      return data;
    });
  });
};

export default getCustomersFromGroup;