import React, { Component } from 'react';
import moment from 'moment';
import {
  Button,
  Checkbox,
  Header,
  Modal,
  Icon,
  Transition,
} from 'semantic-ui-react';
import _ from 'lodash';
import {
  Auth,
  API,
  graphqlOperation, 
} from 'aws-amplify';
import VitalsDatePicker from '../../components/VitalsDatePicker/VitalsDatePicker';
import CustomerDropdown from '../../components/CustomerDropdown/CustomerDropdown';
import ThresholdForm from '../../components/ThresholdsForm/ThresholdsForm';
import VitalsLineChart from '../../components/VitalsLineChart/VitalsLineChart';
import ExportPDF from '../../components/ExportPDF/ExportPDF';
import CustomerTable from '../../components/CustomerTable/CustomerTable'; 
import Notifications from '../../components/Notifications/Notifications';
import AlertLog from '../../components/AlertLog/AlertLog';
import './Dashboard.scss';
import '../../vendor/Loading.scss';
import 'semantic-ui-less/semantic.less';
import OfficeDropdown from '../../components/OfficeDropdown/OfficeDropdown';

import getCustomersFromGroup from '../../functions/getCustomersFromGroup';
import getMasterThresholdLimits from '../../functions/getMasterThresholdLimits';
import getVitalsDataForCustomer from '../../functions/getVitalsDataForCustomer';
import getOfficesByAccount from '../../functions/getOfficesByAccount';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    // Initial State
    this.state = {
      group: '', // logged-in users group
      tableView: false, // view of table for customer vitals
      alertLogView: false, // view of alert log
      allCustomers: [], // array of customer object belonging to state.group
      filteredCustomers: [], // array of customer object currently being displayed
      currentCustomerIndex: null, // current selected customer[] index
      startDate: moment().subtract(30, 'days'), // user-picked start date: handled by VitalsDatePicker
      endDate: moment(), // user-picked end date: handled by VitalsDatePicker
      viewingPDF: false, // boolean if ExportPDF is currently being rendered
      notificationsView: false, // boolean to view the notifications tab
      alerts: [], // notification alerts that will be viewed on per user basis
      unaddressedAlerts: [], // notifications view on the top bar that will be falsy values
      currentLoggedInUser: '', // set the state to the user that is logged in
      thresholdModalOpen: false, // set to false when the modal is closed
      toggleTransition: true, // transition for notifications button
      masterThresholdLimits: [], // master threshold limits for unset thresholds,
      offices: [], // offices for logged in group
      currentOffice: '', // current viewing office
    };
  }

  // await the returned group and search using the group as param
  async componentDidMount() {
    // get current logged in user
    const user = await Auth.currentSession();
    const group = user.accessToken.payload['cognito:groups'][0];

    // get offices for user
    const officesGQL = await getOfficesByAccount(group)
      .then((data) => data)
      .catch((error) => { throw error; });

    // set initial state
    getCustomersFromGroup(group).then(async (res) => {
      this.setState({
        group,
        filteredCustomers: _.sortBy(res, ['customer_name']),
        allCustomers: _.sortBy(res, ['customer_name']),
        currentLoggedInUser: user.idToken.payload['cognito:username'],
        offices: officesGQL.data.listOfficeIDS.items,
        masterThresholdLimits: await getMasterThresholdLimits(group),
      });
    });
  }

  render() {
    const {
      startDate,
      endDate,
      toggleTransition,
      unaddressedAlerts,
      group,
      filteredCustomers,
      currentCustomerIndex,
      viewingPDF,
      tableView,
      alertLogView,
      currentLoggedInUser,
      masterThresholdLimits,
      currentOffice,
      offices,
      thresholdModalOpen,
      allCustomers,
      notificationsView,
    } = this.state;

    console.log('state', this.state);

    // don't render if you don't have data
    if (startDate === undefined) return null;

    /**
     * sets state.startDate and state.endDate
     * @param {Object} startDate - moment object
     * @param {Object} endDate   - moment object
     * @returns {void} updates this.state
     */
    const setDateRange = ({ newStartDate, newEndDate }) => {
      this.setState({
        startDate: newStartDate || startDate,
        endDate: newEndDate || endDate,
      });
    };

    // remove alert from state that is false
    // const removeAddressedAlerts = (alert) => {
    //   this.setState({
    //     unaddressedAlerts: _.filter(
    //       unaddressedAlerts,
    //       alert,
    //     ),
    //   });
    // };
    const removeAddressedAlerts = (officeName) => {
      // GQL query to get false addressed alerts to group
      API.graphql(graphqlOperation(`query getActiveAlerts {
        getActiveAlerts( officeId:"${officeName}" addressed:"false") {
            items {
                flag
                xmit_id
                addressed
                officeId
                carePlanId
                flag
                type
                timestamp 
                limitOver
                measuredAt
                   
            }
        }
      }`)).then((alerts) => { 
        console.log('UNADDRESSED ALERTS CHANGED', alerts);
        this.setState({ unaddressedAlerts: alerts.data.getActiveAlerts.items });
      }).catch(err => console.error(err));
    }

    // api call for the most current data based on a threshold change
    const setThresholdRefresh = async () => {
      getCustomersFromGroup(group).then((customers) => {
        this.setState({ masterThresholdLimits: customers });
      });
    };

    // Select different customer and reset to past 30 days
    const setCustomerIndex = (index) => {
      this.setState({
        currentCustomerIndex: index,
        startDate: moment().subtract(30, 'days').startOf('day'),
        endDate: moment().endOf('day'),
      });
    };

    /**
     * sets state.viewingPDF
     * @returns {void} updates this.viewingPDF to false
     */
    const stopViewingPDF = () => {
      this.setState({ viewingPDF: false });
    };

    /**
     * sets state.viewingPDF
     * @returns {void} updates this.tableView to false
     */
    const setTableView = (value) => {
      this.setState({ tableView: value });
    };

    /**
     * formats phone numbers coming from crmblack
     * @returns {string} formatted number
     */
    const phoneNumberFormat = (number) => {
      if (_.includes(number, '-')) return number;
      return `${number.substr(0, 3)}-${number.substr(3, 3)}-${number.substr(6, 9)}`;
    };

    const setPresetRange = (days) => {
      setDateRange({ newStartDate: moment().subtract(days, 'days').startOf('day'), newEndDate: moment().endOf('day')})
    }

    // if no vitals data exists for the current customer, fetch it
    if (currentCustomerIndex !== null) {
      if (!filteredCustomers[currentCustomerIndex].data) {
        getVitalsDataForCustomer(
          filteredCustomers[currentCustomerIndex],
        )
          .then((data) => {
            this.setState({
              filteredCustomers: Object.assign(filteredCustomers, { [currentCustomerIndex]: data }),
              startDate: moment().subtract(30, 'days').startOf('day'),
              endDate: moment().endOf('day'),
            });
          })
          .catch((error) => { console.log(error); });
      }
    }

    return (
      <div className="dashboard__container">
        { viewingPDF ? (
          <ExportPDF
            stopViewingPDF={stopViewingPDF}
            customer={filteredCustomers[currentCustomerIndex]}
            startDate={startDate}
            endDate={endDate}
            tableView={tableView}
            setTableView={setTableView}
          />
        )
          : null}
        <div>
          <div className="dashboard__row-1">
            <div>

              {/* Button for Enabling PDF view */}
              <Button
                disabled={
                  (currentCustomerIndex !== null && currentCustomerIndex !== undefined)
                    ? (tableView || !filteredCustomers[currentCustomerIndex].data
                    || alertLogView) : true
                  }
                onClick={() => { this.setState({ viewingPDF: true }); }}
                primary
              >
                Export PDF
              </Button>


              {/* Modal for displaying threshold form */}
              <Modal
                size="tiny"
                onClose={() => this.setState({ thresholdModalOpen: false })}
                open={thresholdModalOpen}
                trigger={(
                  <Button
                    primary
                    disabled={
                      (currentCustomerIndex !== null && currentCustomerIndex !== undefined)
                        ? !filteredCustomers[currentCustomerIndex].data : true
                      }
                    onClick={() => this.setState({ thresholdModalOpen: true })}
                  >
                      Threshold Management
                  </Button>
                )}
              >
                <Header size="tiny">
                  <Icon name="user md" />
                  <Header.Content>
                    Threshold Management ---
                    <i>{currentCustomerIndex && filteredCustomers[currentCustomerIndex].customer_name}</i>
                  </Header.Content>
                  <Modal.Actions>
                    <Button
                      style={{
                        position: 'absolute', marginLeft: '400px', top: '18px', width: '25px', height: '25px',
                      }}
                      onClick={() => this.setState({ thresholdModalOpen: false })}
                      primary
                      icon
                      labelPosition="right"
                    >
                      Exit
                      <Icon name="right arrow" />
                    </Button>
                  </Modal.Actions>
                </Header>

                <Modal.Content>
                  <ThresholdForm
                    initialState={filteredCustomers[currentCustomerIndex]}
                    setThresholdRefresh={setThresholdRefresh}
                    currentLoggedInUser={currentLoggedInUser}
                    masterLimits={masterThresholdLimits ? masterThresholdLimits[0] : null}
                    groupName={group}
                  />
                </Modal.Content>

              </Modal>

              {/* Button for enabling alert log view */}
              <Button
                primary
                disabled={(currentCustomerIndex !== null && currentCustomerIndex !== undefined) ? !filteredCustomers[currentCustomerIndex].data : true}
                onClick={() => this.setState({alertLogView: !alertLogView })}
              >
              Alert Log
              </Button>
            </div>
            <div className="header__customer-info">
              <div className="dashboard__customer-attribute-container">
                <h4 className="dashboard__customer-attribute-header">Group</h4>
                <h4 className="dashboard__customer-attribute-label">{group === '' ? '-' : _.startCase(group)}</h4>
              </div>
              <div className="dashboard__customer-attribute-container dashboard__customer-attribute-container--name">
                <h4 className="dashboard__customer-attribute-header">Name</h4>
                <h4 className="dashboard__customer-attribute-label">{currentCustomerIndex !== null ? filteredCustomers[currentCustomerIndex].customer_name : '-'}</h4>
              </div>
              <div className="dashboard__customer-attribute-container">
                <h4 className="dashboard__customer-attribute-header">Phone</h4>
                <h4 className="dashboard__customer-attribute-label">
                  {
                  phoneNumberFormat(currentCustomerIndex !== null ? filteredCustomers[currentCustomerIndex].customer_phone : '-')
                  }
                </h4>
              </div>
              <div className="dashboard__customer-attribute-container">
                <h4 className="dashboard__customer-attribute-header">DOB</h4>
                <h4 className="dashboard__customer-attribute-label">{currentCustomerIndex !== null ? filteredCustomers[currentCustomerIndex].date_of_birth : '-'}</h4>
              </div>
              <div className="dashboard__customer-attribute-container">
                <h4 className="dashboard__customer-attribute-header">Primary Care Physician</h4>
                <h4 className="dashboard__customer-attribute-label">
                  {
                    (() => {
                      if (currentCustomerIndex == null) return '-';
                      return (filteredCustomers[currentCustomerIndex].doctor_name
                        ? filteredCustomers[currentCustomerIndex].doctor_name : 'N/A');
                    })()
                  }
                </h4>
              </div>
              <div className="dashboard__customer-attribute-container">
                <h4 className="dashboard__customer-attribute-header">Device ID</h4>
                <h4 className="dashboard__customer-attribute-label">{currentCustomerIndex !== null ? filteredCustomers[currentCustomerIndex].xmit_id : '-'}</h4>
              </div>
              <div className="dashboard__customer-attribure-container">
                <h4 className="dashboard__customer-attribure-header">Activation Date</h4>
                <h4 className="dashboard__customer-attribute-label">
                  {
                    currentCustomerIndex !== null ? moment(filteredCustomers[currentCustomerIndex].activation_date).format('MM/DD/YYYY') : '-'
                  }
                </h4>
              </div>
            </div>

            <div className="dashboard__row-1-item">

              {/* Office Dropdown component */}
              <OfficeDropdown
                disabled={(currentCustomerIndex !== null && currentCustomerIndex !== undefined) ? Boolean(!filteredCustomers[currentCustomerIndex].data) : false}
                offices={offices} 
                defaultValue={currentOffice}
                onChange={
                  (officeName) => {
                    // GQL query to get false addressed alerts to group
                    API.graphql(graphqlOperation(`query getActiveAlerts {
                      getActiveAlerts( officeId:"${officeName}" addressed:"false") {
                          items {
                              flag
                              xmit_id
                              addressed
                              officeId
                              carePlanId
                              flag
                              type
                              timestamp 
                              limitOver
                              measuredAt
                                 
                          }
                      }
                    }`)).then((alerts) => { 
                      console.log('UNADDRESSED ALERTS CHANGED', alerts);
                      this.setState({ unaddressedAlerts: alerts.data.getActiveAlerts.items });
                    });

                    // GQL query to get false addressed alerts to group
                    API.graphql(graphqlOperation(`query getGroupAlerts {
                      getGroupAlerts( officeId:"${officeName}" carePlanId:"${group}") {
                          items {
                              flag
                              xmit_id
                              addressed
                              officeId
                              carePlanId
                              flag
                              type
                              timestamp 
                              limitOver
                              measuredAt
                                 
                          }
                      }
                    }`)).then((alerts) => { 
                      console.log('ALERTS CHANGED', alerts);
                      this.setState({ alerts: alerts.data.getGroupAlerts.items });
                    });

                    this.setState({
                      currentOffice: officeName,
                      filteredCustomers: _.filter(allCustomers, ['office', officeName]),
                      currentCustomerIndex: null,
                    });
                  }
                }
              />

              {/* Customer Dropdown component */}
              <CustomerDropdown
                disabled={((currentCustomerIndex !== null && currentCustomerIndex !== undefined) ? Boolean(!filteredCustomers[currentCustomerIndex].data) : false) || currentOffice === ''}
                group={group}
                customers={filteredCustomers}
                currentCustomerIndex={currentCustomerIndex}
                onChange={
                  (index) => {
                    if (filteredCustomers[index].startDate === undefined) {
                      this.setState({
                        currentCustomerIndex: index,
                      });
                    } else {
                      this.setState({
                        currentCustomerIndex: index,
                        startDate: moment().subtract(30, 'days').startOf('day'),
                        endDate: moment().endOf('day'),
                      });
                    }
                  }
                }
              />

              <VitalsDatePicker
                disabled={(currentCustomerIndex !== null && currentCustomerIndex !== undefined) ? !filteredCustomers[currentCustomerIndex].data : true}
                customer={filteredCustomers[currentCustomerIndex]}
                startDate={startDate}
                endDate={endDate}
                setDateRange={setDateRange}
              />
            </div>
          </div>
          
          <div className="dashboard__row-2">

            <div className="dashboard__notifications-flex-1">

              <Transition animation="tada" duration={400} visible={toggleTransition}>
                {/* Button for viewing the notifications */}
                <Button
                  className="notifications-button"
                  size="tiny"
                  style={unaddressedAlerts.length ? { color: 'white', backgroundColor: '#f24b4b' } : { color: 'white', backgroundColor: '#758299' }}
                  icon
                  onClick={() => {
                    this.setState({ 
                      notificationsView: !notificationsView,
                      toggleTransition: !toggleTransition,
                    });
                  }}
                >
                  <Icon name="alarm" />
                  <b className="dashboard_notifications_number-font">{unaddressedAlerts.length ?` ${unaddressedAlerts.length}`:null}</b>
                </Button>
              </Transition>

              <Notifications 
                on={notificationsView} 
                alerts={this.state.unaddressedAlerts} 
                customers={this.state.filteredCustomers} 
                setIndex={setCustomerIndex}
                removeAddressedAlerts={removeAddressedAlerts}
                currentOffice={this.state.currentOffice}
              />

              <Checkbox 
                toggle
                disabled={(this.state.currentCustomerIndex !== null && this.state.currentCustomerIndex !== undefined) ? !this.state.filteredCustomers[this.state.currentCustomerIndex].data : true} 
                onChange={() => {
                  this.setState({tableView: !this.state.tableView})
                  this.setState({alertLogView: false})
                  }}
                label='Table View'
              />

            </div>

            <div className='vitals-date-picker_date-presets2'>
              <Button onClick={() => setPresetRange(7)}>Past 7 Days</Button>
              <Button onClick={() => setPresetRange(14)}>Past 14 Days</Button>
              <Button onClick={() => setPresetRange(30)}>Past 30 Days</Button>
              <Button onClick={() => setPresetRange(90)}>Past 90 Days</Button>
            </div>
          </div>
        </div>

        {/* Main Dashboard Content */}
        { 

        // No customers in selected office
        (this.state.filteredCustomers.length === 0 && this.state.allCustomers.length !== 0) ? (
          <div className='dashboard__message-container'>
            <h1>No customers found for office "{this.state.currentOffice}"</h1>
          </div>

        // No office selected, no patient selected (initial screen)
        ) : (this.state.currentCustomerIndex === null && this.state.currentOffice === '') ? (
          <div className='dashboard__message-container'>
            <h1>Select an Office</h1>
          </div>
        ) :

        // Office is selected, no patient selected
        (this.state.currentCustomerIndex === null && this.state.allCustomers.length !== 0) ? (
          <div className='dashboard__message-container'>
            <h1>Select a Patient</h1>
          </div>
        ) : 
        
        // fetching data
        (this.state.currentCustomerIndex === null || !this.state.filteredCustomers[this.state.currentCustomerIndex].data) ? (
          <div className="loading__container">
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
          </div>
        ) : 

        // display alert log
         this.state.alertLogView ? (
           <AlertLog
            data={this.state.filteredCustomers[this.state.currentCustomerIndex].alerts}
            update={this.state.filteredCustomers[this.state.currentCustomerIndex]}
             />
         ) :
         
         // render dashboard
         (
          !this.state.tableView ? (
            <div>
              <div className="dashboard__graphs-grid-1">
                
                {/* BPM Area Chart */}
                <VitalsLineChart
                  data={this.state.filteredCustomers[this.state.currentCustomerIndex].data.BPM}
                  dataLabel={"BPM"}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  areaColor={"#f24b4b"}
                  thresholdMax={this.state.filteredCustomers[this.state.currentCustomerIndex].thresholdDataObject.BPM ? 
                    this.state.filteredCustomers[this.state.currentCustomerIndex].thresholdDataObject.BPM.maximum : (
                    this.state.masterThresholdLimits[0].BPMHigh? this.state.masterThresholdLimits[0].BPMHigh.S: null)}
                  thresholdMin={this.state.filteredCustomers[this.state.currentCustomerIndex].thresholdDataObject.BPM ? 
                      this.state.filteredCustomers[this.state.currentCustomerIndex].thresholdDataObject.BPM.minimum :( 
                      this.state.masterThresholdLimits[0].BPMLow? this.state.masterThresholdLimits[0].BPMLow.S: null)}
                />

                {/* Oxygen Area Chart */}
                <VitalsLineChart
                  data={this.state.filteredCustomers[this.state.currentCustomerIndex].data.Oxygen}
                  dataLabel={"Oxygen"}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  areaColor={"#4BCBF3"}
                  dataMax={100}
                  dataMinOffset={5}
                  thresholdMax={this.state.filteredCustomers[this.state.currentCustomerIndex].thresholdDataObject.Oxygen ?
                    this.state.filteredCustomers[this.state.currentCustomerIndex].thresholdDataObject.Oxygen.maximum :
                    this.state.masterThresholdLimits[0].OxSatHigh? this.state.masterThresholdLimits[0].OxSatHigh.S: null}
                  thresholdMin={this.state.filteredCustomers[this.state.currentCustomerIndex].thresholdDataObject.Oxygen ? 
                      this.state.filteredCustomers[this.state.currentCustomerIndex].thresholdDataObject.Oxygen.minimum :
                      this.state.masterThresholdLimits[0].OxSatLow? this.state.masterThresholdLimits[0].OxSatLow.S: null}
                />
              </div>

              <div className="dashboard__graphs-grid-2">

                {/* Blood Pressure Area Chart */}
                <VitalsLineChart
                  data={this.state.filteredCustomers[this.state.currentCustomerIndex].data.BloodPressure}
                  dataLabel={"Systolic"}
                  dataLabel2={"Diastolic"}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  areaColor={"#BB84D0"}
                  areaColor2={"#9944BB"}
                  dataMin={40}
                  title="Blood Pressure"
                  id="BloodPressure"
                  SystolicMax={this.state.filteredCustomers[this.state.currentCustomerIndex].thresholdDataObject.Systolic ?
                        this.state.filteredCustomers[this.state.currentCustomerIndex].thresholdDataObject.Systolic.maximum :(
                        this.state.masterThresholdLimits[0].SystolicHigh? this.state.masterThresholdLimits[0].SystolicHigh.S: null)}
                  SystolicMin={this.state.filteredCustomers[this.state.currentCustomerIndex].thresholdDataObject.Systolic ? 
                        this.state.filteredCustomers[this.state.currentCustomerIndex].thresholdDataObject.Systolic.minimum :(
                        this.state.masterThresholdLimits[0].SystolicLow? this.state.masterThresholdLimits[0].SystolicLow.S: null)}
                  DiastolicMax={this.state.filteredCustomers[this.state.currentCustomerIndex].thresholdDataObject.Diastolic ?
                        this.state.filteredCustomers[this.state.currentCustomerIndex].thresholdDataObject.Diastolic.maximum : (
                        this.state.masterThresholdLimits[0].DiastolicHigh? this.state.masterThresholdLimits[0].DiastolicHigh.S:null)}
                  DiastolicMin={this.state.filteredCustomers[this.state.currentCustomerIndex].thresholdDataObject.Diastolic ? 
                        this.state.filteredCustomers[this.state.currentCustomerIndex].thresholdDataObject.Diastolic.minimum :(
                        this.state.masterThresholdLimits[0].DiastolicLow? this.state.masterThresholdLimits[0].DiastolicLow.S: null)}
                />
              </div>

              <div className="dashboard__graphs-grid-3">

                {/* Weight Area Chart */}
                <VitalsLineChart
                  data={this.state.filteredCustomers[this.state.currentCustomerIndex].data.Weight}
                  dataLabel={"Weight"}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  areaColor={"#49C0AD"}
                  thresholdMax={this.state.filteredCustomers[this.state.currentCustomerIndex].thresholdDataObject.Weight ? 
                    this.state.filteredCustomers[this.state.currentCustomerIndex].thresholdDataObject.Weight.maximum : null}
                  thresholdMin={this.state.filteredCustomers[this.state.currentCustomerIndex].thresholdDataObject.Weight ? 
                      this.state.filteredCustomers[this.state.currentCustomerIndex].thresholdDataObject.Weight.minimum : null}
                />

                {/* Glucose Area Chart */}
                <VitalsLineChart
                  data={this.state.filteredCustomers[this.state.currentCustomerIndex].data.Glucose}
                  dataLabel={"Glucose"}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  areaColor={"#49C0AD"}
                  thresholdMax={this.state.filteredCustomers[this.state.currentCustomerIndex].thresholdDataObject.Glucose ? 
                    this.state.filteredCustomers[this.state.currentCustomerIndex].thresholdDataObject.Glucose.maximum :(
                      this.state.masterThresholdLimits[0].GlucoseHigh? this.state.masterThresholdLimits[0].GlucoseHigh.S:null)}
                  thresholdMin={this.state.filteredCustomers[this.state.currentCustomerIndex].thresholdDataObject.Glucose ? 
                      this.state.filteredCustomers[this.state.currentCustomerIndex].thresholdDataObject.Glucose.minimum : (
                        this.state.masterThresholdLimits[0].GlucoseLow? this.state.masterThresholdLimits[0].GlucoseLow.S:null)} 
                />

                {/* Body Temperature Area Chart */}
                <VitalsLineChart
                  data={this.state.filteredCustomers[this.state.currentCustomerIndex].data.BodyTemperature}
                  dataLabel={"BodyTemperature"}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  areaColor={"#49C0AD"}
                  thresholdMax={this.state.filteredCustomers[this.state.currentCustomerIndex].thresholdDataObject.Temperature ?
                    this.state.filteredCustomers[this.state.currentCustomerIndex].thresholdDataObject.Temperature.maximum : null}
                  thresholdMin={this.state.filteredCustomers[this.state.currentCustomerIndex].thresholdDataObject.Temperature ? 
                      this.state.customers[this.state.currentCustomerIndex].thresholdDataObject.Temperature.minimum : null}
                />
              </div>
              {/* Customer Table (Conditional)*/}
            </div>
          ) 
          : 
            <CustomerTable 
              data={this.state.filteredCustomers[this.state.currentCustomerIndex].data.AllReadings} 
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            /> 
         )
          }
      </div>
    );
  }
} 

export default Dashboard;
