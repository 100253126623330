// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "emr-health-dashboard-hosting",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dfqduxyrsjgef.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:fe34df84-a594-4ae2-b55b-8526e3eef98c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ezvY0ePmb",
    "aws_user_pools_web_client_id": "s9fi7avt81hipnu2tjgmdlte",
    "oauth": {}
};


export default awsmobile;
