import React  from "react";
import _ from "lodash";
import axios from "axios";
import { Table, Button } from 'semantic-ui-react'
import { Auth } from "aws-amplify";
import "./Notifications.scss" 
import moment from 'moment'

const Notifications = ({ on, alerts, customers, setIndex, removeAddressedAlerts, currentOffice }) => {

  // on button press post to api and log the response || error
  const addressedAlert = async (item) => {
  const headerData =  await Auth.currentSession()
    axios.post("https://rwgi3alw14.execute-api.us-east-1.amazonaws.com/prod/postalerts", {
      "xmit_id": item.xmit_id,
      "addressed":"true",
      "addressedBy": await headerData.idToken.payload["cognito:username"],
      "timestamp": item.timestamp,
      "addressedTime": moment.utc().local().format('MM-DD-YYYY LT')
    })
    .then(response => console.log(response))
    .catch(err => console.log(err))
    // remove the alert on action
   await removeAddressedAlerts(currentOffice);
  }

  return (
    on ? (
      <div className="notifications">
        <Table collapsing compact size="small" >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Patient Name</Table.HeaderCell>
              <Table.HeaderCell>Alert</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Addressed</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(_.reverse(_.sortBy(alerts, ["timestamp"])), (item) => {
              return (
                <Table.Row key={_.shuffle(item.timestamp)} >
                <Table.Cell
                  key={_.shuffle(item.timestamp)} 
                  textAlign="center" 
                  selectable
                  onClick={event => 
                    setIndex(_.findIndex(customers,{ xmit_id: item.xmit_id}))
                  }
                >
                  {_.find(customers, { xmit_id: item.xmit_id}) ? _.find(customers, { xmit_id: item.xmit_id}).customer_name : null}
                </Table.Cell>
                <Table.Cell key={_.shuffle(item.timestamp)}>{`${item.flag}`}</Table.Cell>
                <Table.Cell key={_.shuffle(item.timestamp)}>{item.type? item.type: "N/A"}</Table.Cell>
                <Table.Cell key={_.shuffle(item.timestamp)}>
                  <Button 
                    key={_.shuffle(item.timestamp)}
                    primary 
                    onClick={event => addressedAlert(item)}>Yes
                    </Button>
                  </Table.Cell>
              </Table.Row>
            );
            })}
          </Table.Body>
        </Table>
      </div>
    ) : null
  );
}

export default Notifications