import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Auth } from "aws-amplify";
import './Header.scss'

class Header extends Component {

	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };

		this.signOut = this.signOut.bind(this);
	}

	toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

	async signOut() {
		await Auth.signOut();
	await	window.location.reload(true)
	}

	render() {
	
		return (
			<div>
				<div className='nav-top'>
					<a href='/'>
						<img alt='' className='nav-top__logo' src={'/img/logo.png'} />
					</a>
					<a className='nav-top__sign-out' href="#" onClick={this.signOut}>
						<FontAwesomeIcon icon="sign-out-alt"/>  Sign Out
					</a>
				</div>
			</div>
		);
	}
}

export default Header;