import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Button,
  Form,
  Input,
  Select,
  Grid,
  Container,
  Message
} from "semantic-ui-react";
import axios from "axios";
import ThresholdsTable from "./ThresholdsTable/ThresholdsTable";

const ThresholdForm = ({ initialState, setThresholdRefresh, currentLoggedInUser, masterLimits, groupName }) => {
  
  //component state initialization
  const [thresholdList, setThresholdList] = useState([]);
  const [threshold, setThreshold] = useState({});
  const [newThreshold, setNewThreshold] = useState({});
  const [selectedVital, setSelectedVital] = useState("");
  const [minimum, setMinimum] = useState("");
  const [maximum, setMaximum] = useState("");
  const [localOwner, setLocalOwner] = useState(currentLoggedInUser);
  const [formLoading, setFormLoading] = useState(false);
  const [successfulSubmit, setSuccessfulSubmit] = useState(false);
  const [failedSubmit, setFailedSubmit] = useState(false);
  const [minMaxError, setMinMaxError] = useState(false);
  const [failedSubmitMessage, setFailedSubmitMessage] = useState("");
  const [thresholdDivisorError, setThresholdDivisorError] = useState(false);

  //set the list of thresholds according to the dashboard state & change list on every submit
  useEffect(() => {
    setThresholdList(initialState.thresholdDataArray);
  }, [successfulSubmit]);

  // handle each click (in form) to set the threshold
  useEffect(() => {
    setMaximum(threshold ? threshold.maximum : "");
    setMinimum(threshold ? threshold.minimum : "");
    // setLocalOwner(threshold ? threshold.localOwner : "");
  }, [threshold]);

  //detect changes on every rerender for error handling
  useEffect(() => {
    isNaN(minimum) || isNaN(maximum)
      ? setMinMaxError(true)
      : setMinMaxError(false);
    maximum / minimum <= 1
      ? setThresholdDivisorError(true)
      : setThresholdDivisorError(false);
    console.log("MinMaxError", minMaxError);
  }, [minimum, maximum]);
  

  //repopulate form when dropdown changes 
  const handleThresholdChange = vital => {
    let newVital = _.filter(thresholdList, { vitalName: vital });
    if (newVital !== []) {
      console.log("vital", vital, "new vital", newVital);
      setSelectedVital(vital);
      setFailedSubmit(false);
      setSuccessfulSubmit(false);
      setTimeout(() => {
        setThreshold(newVital[0]);
        setNewThreshold({ ...threshold });
        setFormLoading(false);
      }, 600);
      setFormLoading(true);
    }
    setThreshold({});
  };

  //handle keystroke changes for form 
  const handleChange = propertyName => event => {
    event.preventDefault();
    console.log(`${propertyName}`, event.target.value);
    setMaximum(propertyName === "maximum" ? event.target.value : maximum);
    setMinimum(propertyName === "minimum" ? event.target.value : minimum);
    // setLocalOwner(
    //   propertyName === "localOwner" ? event.target.value : localOwner
    // );
    setSuccessfulSubmit(false);

    //create a new threshold based on the event prop
    setNewThreshold(
      threshold
        ? {
            ...threshold,
            [propertyName]: event.target.value,
            orbId: initialState.lead_id,
            thresholdCarePlanId: groupName,
            localOwner:localOwner,
          }
        : {
            maximum: propertyName === "maximum" ? event.target.value : maximum,
            minimum: propertyName === "minimum" ? event.target.value : minimum,
            localOwner:  localOwner,
            orbId: initialState.lead_id.toString(),
            thresholdCarePlanId: groupName,
            vitalName: selectedVital,
            xmitId: initialState.xmit_id,
            createdAt: new Date().toJSON().toString()
          }
    );
  };

  //api calls on submit  
  const submitAction = async () => {
    console.log(newThreshold);
    await axios
      .post(
        "https://bvaotch7t9.execute-api.us-east-1.amazonaws.com/prod",
        JSON.stringify(newThreshold)
      )
      .then(response => {
        console.log(response.data);
        if (response.data.statusCode !== 200) {
          setSuccessfulSubmit(false);
          setFailedSubmit(true);
          setFailedSubmitMessage(response.data.message);
        } else {
          setSuccessfulSubmit(true);
          setFailedSubmit(false);
        }
      })
      .catch(console.err);

    await setThresholdRefresh();
  };
  //options on the dropdown 
  const vitalOptions = [
    { key: "o", text: "Oxygen", value: "Oxygen" },
    { key: "bpm", text: "Heart Rate", value: "BPM" },
    { key: "sys", text: "Systolic", value: "Systolic" },
    { key: "dia", text: "Diastolic", value: "Diastolic" },
    { key: "w", text: "Weight", value: "Weight" },
    { key: "g", text: "Glucose", value: "Glucose" },
    { key: "t", text: "Body Temperature", value: "Temperature" }
  ];

  return (
    <Container>
     {masterLimits? (
      <Message warning>
        <Message.Header>Default thresholds are set for your Organization </Message.Header> 
      </Message>
             ): 
             null}
      <Form
        loading={formLoading}
        success={successfulSubmit}
        error={failedSubmit}
      >
        <Form.Group>
          <Grid columns={1} textAlign="center">
            <Grid.Row>
              <Form.Field
                fluid
                control={Select}
                label="Select Vital Threshold"
                options={vitalOptions}
                placeholder="None"
                onChange={(e, { value }) => handleThresholdChange(value)}
              />
            </Grid.Row>
     
            {/* 
               Code for local owner of each threshold -- possible future use 
               <Grid.Row>
              <Form.Field
                disabled={!selectedVital}
                control={Input}
                label="Local Owner"
                value={localOwner}
                onChange={handleChange("localOwner")}
              />
            </Grid.Row> */}
            <Grid.Row>
              <Form.Field
                disabled={!selectedVital}
                error={minMaxError || thresholdDivisorError}
                control={Input}
                label="Minimum"
                value={minimum}
                onChange={handleChange("minimum")}
              />
            </Grid.Row>
            <Grid.Row>
              <Form.Field
                disabled={!selectedVital}
                error={minMaxError || thresholdDivisorError}
                control={Input}
                label="Maximum"
                value={maximum}
                onChange={handleChange("maximum")}
              />
            </Grid.Row>
            <Grid.Row>
              <Form.Field
                disabled={!selectedVital}
                control={Input}
                readOnly
                label="Threshold Routing Plan"
                value={groupName}
                onChange={handleChange("thresholdCarePlanId")}
              />
            </Grid.Row>
            <Grid.Row>
              <Form.Field
                disabled={
                  minMaxError ||
                  thresholdDivisorError ||
                  minimum === "" ||
                  maximum === "" ||
                  localOwner === ""
                }
                control={Button}
                onClick={submitAction}
              >
                Submit
              </Form.Field>
            </Grid.Row>
            <Grid.Row>
              <Message
                success
                content={`${newThreshold.vitalName} threshold has been updated`}
              />
            </Grid.Row>
            <Grid.Row>
              <Message
                error
                content={`There was an error updating ${
                  newThreshold.vitalName
                } threshold "message: ${failedSubmitMessage}" `}
              />
            </Grid.Row>
          </Grid>
        </Form.Group>
      </Form>
      <ThresholdsTable initialState={initialState.thresholdDataObject} />
    </Container>
  );
};

export default ThresholdForm;
