import moment from 'moment';

/**
  * rounds date to MM/DD/YYYY and pushes to formattedData.dates
  * @param {Object} formattedData - customer's formatted vitals data
  * @param {Object} reading - singular unformatted vitals reading
  * @returns {void} pushes date value to formattedData if doesn't exists
  */
const pushDate = (formattedData, reading) => {
  if(!formattedData.Dates.includes(moment.utc(reading.TimeStamp.S).local().format('MM/DD/YYYY')))
    formattedData.Dates.push(moment.utc(reading.TimeStamp.S).local().format('MM/DD/YYYY'));
}

export default pushDate;