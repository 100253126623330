import axios from 'axios';


//get api key to make graphql request, header grants authorization
const getApiInfo = async ({idToken: {jwtToken}}) => {
    try{
   
      const  { data:{key} } = await axios.post("https://8n3r6aww29.execute-api.us-east-1.amazonaws.com/prod", {}, {
        headers: { 
             Authorization: jwtToken,
        }    
    }
     
   ) 
      return key
}
    catch(err){
      console.log(err)
}
}

export default getApiInfo