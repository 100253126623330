import React, { useState } from 'react';
import { PDFViewer, Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import html2canvas from 'html2canvas';
import moment from 'moment';
import { Button, Icon } from 'semantic-ui-react';
import DataToPretty from '../../functions/dataToPretty';

import './ExportPDF.scss';

let graphsTemplate = [
  { name: 'BPM', data: null },
  { name: 'Oxygen', data: null },
  { name: 'BloodPressure', data: null },
  { name: 'Weight', data: null },
  { name: 'Glucose', data: null },
  { name: 'BodyTemperature', data: null },
];
let graphs = [];

const ExportPDF = ({ stopViewingPDF, customer, startDate, endDate, }) => {
  const [ loadingGraphs, setLoadingGraphs ] = useState(-1);
  // -1 == not generating graphs, graphs not generated
  //  0 == generating graphs
  //  1 == not generating graphs, graphs already generated

  // if not generating graphs and graphs not generated
  if(loadingGraphs === -1) {

    // start generating graphs
    setLoadingGraphs(0);

    // generates graph[] using graphsTemplate[] using an array of promises
    graphs = graphsTemplate.map((attribute) => {
      let graph = document.getElementById(attribute.name);
      return html2canvas(graph, { logging: false })
      .then((canvas) => ({ name: attribute.name, data: canvas.toDataURL() }));
    });

    // once graphs promise array resolves, replace with resolved values
    Promise.all(graphs).then((results) => {
      graphs = results;
      setLoadingGraphs(1);
    })
  }

  return (
    <div className='pdf-viewer__container' onClick={ stopViewingPDF }>
       <Button className="pdf-exit-button"
         size="small" 
         primary 
         icon 
         labelPosition='left'
         onClick={stopViewingPDF}>
            Exit
          <Icon name='left arrow' />
        </Button>
      {loadingGraphs !== 1 ? null : 
      <PDFViewer className='pdf-viewer'>
        <Document wrap={false} >
          <Page size="A4" style={styles.page} wrap={true}>

            {/* Header (fixed) */}
            <View style={styles.section} fixed>
              <Text style={styles.addisonCareLogo}>Addison Care</Text>
              <Text style={styles.customerName}>{customer.customer_name}</Text>
            </View>

            {/* Customer Info */}
            <View style={[styles.section, styles.customerData]}>
              {/* <Text style={styles.customerName}>{customer.customer_name}</Text> */}
            </View>

            {/* Report Info */}
            <View style={[styles.section, styles.reportData]}>
              <Text>Report Date: {moment(new Date()).format('MM-DD-YYYY LT')}</Text>
              <Text>Data from {startDate.startOf('day').format('MM-DD-YYYY LT')} to {endDate.endOf('day').format('MM-DD-YYYY LT')}</Text>
            </View>

            {/* All Readings vitals data table */}
            <View style={styles.section}>

              {/* Header Row */}
              <View style={styles.reading}>
                <Text style={[styles.dateCell, styles.tableHeader]}>Date</Text>
                <Text style={[styles.dataCell, styles.tableHeader]}>BP</Text>
                <Text style={[styles.dataCell, styles.tableHeader]}>HR</Text>
                <Text style={[styles.dataCell, styles.tableHeader]}>WT</Text>
                <Text style={[styles.dataCell, styles.tableHeader]}>O2</Text>
                <Text style={[styles.dataCell, styles.tableHeader]}>BG</Text>
                <Text style={[styles.dataCell, styles.tableHeader]}>BBT</Text>
              </View>     
                
              {/* generate table */}
              { customer.data.AllReadings.map((reading, index) => {
                if(moment(reading.date).isBefore(startDate) || moment(reading.date).isAfter(endDate))
                  return null;
                
                return(
                  <View key={index} style={styles.reading} break={index % 33 === 0 && index !== 0 ? true : false}>
                      <Text style={styles.dateCell}>{reading.date}</Text>
                      <Text style={styles.dataCell}>{reading.BloodPressure}</Text>
                      <Text style={styles.dataCell}>{reading.HeartRate}</Text>
                      <Text style={styles.dataCell}>{reading.Weight}</Text>
                      <Text style={styles.dataCell}>{reading.Oxygen}</Text>
                      <Text style={styles.dataCell}>{reading.Glucose}</Text>
                      <Text style={styles.dataCell}>{reading.BodyTemperature}</Text>
                  </View>
                );
              })}
            </View>

            {
              graphs.map((vital, index) => (
                <View key={index}>
                  { customer.data[vital.name].length === 0  ? null
                  :
                    <View break={true}>
                      <Image style={styles.image} src={ graphs[index].data } format='image/png'/>
                      <VitalReadingsTable customer={customer} vital={vital.name} startDate={startDate} endDate={endDate} />
                    </View>
                  }
                </View>
              ))
            }
            
          </Page>
        </Document>
      </PDFViewer>
      }
    </div>
  );
};

const VitalReadingsTable = ({ customer, vital, startDate, endDate }) => {
  if(customer.data[vital].length === 0)
    return null;

  return(
    <View style={styles.section}>

      {/* Header */}
      <View style={styles.reading}>
        <Text style={[styles.dateCell, styles.tableHeader]}>Date</Text>
        <Text style={[styles.dataCell, styles.tableHeader]}>{DataToPretty[vital]}</Text>
        <Text style={[styles.dataCell, styles.tableHeader]}></Text>
        <Text style={[styles.dataCell, styles.tableHeader]}></Text>
        <Text style={[styles.dataCell, styles.tableHeader]}></Text>
        <Text style={[styles.dataCell, styles.tableHeader]}></Text>
        <Text style={[styles.dataCell, styles.tableHeader]}></Text>
      </View>    

      {/* Generate Table  */}
      { customer.data[vital].map((reading, index) => {
        if(moment(reading.date).isBefore(startDate) || moment(reading.date).isAfter(endDate))
          return null;

        return(
          <View key={index} style={styles.reading} break={index % 27 === 0 && index !== 0 ? true : false}>
              <Text style={styles.dateCell}>{moment(reading.date).format('MM-DD-YYYY LT')}</Text>
              <Text style={styles.dataCell}>{reading.value2 ? `${reading.value}/${reading.value2}` : reading.value}</Text>
              <Text style={styles.dataCell}></Text>
              <Text style={styles.dataCell}></Text>
              <Text style={styles.dataCell}></Text>
              <Text style={styles.dataCell}></Text>
              <Text style={styles.dataCell}></Text>
          </View>
        );
      })}
    </View>
  );
};

Font.register({ family: 'Roboto Condensed', 
  fonts: [ 
    { src: '../fonts/RobotoCondensed-Regular.ttf' },
    { src: '../fonts/RobotoCondensed-Bold.ttf', fontWeight: 700 },
  ],
});

Font.register({ family: 'Open Sans', 
  fonts: [ 
    { src: '../fonts/OpenSans-Regular.ttf' },
    { src: '../fonts/OpenSans-Bold.ttf', fontWeight: 700 },
  ],
});

const styles = StyleSheet.create({
  image: {
    marginVertical: 0,
    marginHorizontal: 20,
  },

  addisonCareLogo: {
    fontFamily: 'Roboto Condensed',
    fontWeight: 700,
    fontSize: 30,
    margin: '20 0 0 20',
  },

  customerName: {
    fontFamily: 'Roboto Condensed',
    fontSize: 20,
    margin: '10 0 0 20',
  },

  customerData: {
    fontSize: 10,
    margin: '0 0 0 20',
  },

  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    margin: 0, // bug prevents this from working with wrap
  },

  section: {
    padding: 10,
    fontFamily: 'Open Sans',
  },

  reading: {
    alignItems: 'center',
    flexDirection: 'row',
    fontSize: 10,
    justifyContent: 'space-between',
    padding: '2 0',
    maxWidth: '90%',
    margin: '0 0 0 20',
  },

  reportData: {
    fontSize: 10,
    margin: '0 0 0 20',
  },
  
  tableHeader: {
    fontWeight: 700,
  },

  dateCell: {
    width: '25%',
    fontFamily: 'Open Sans',
  },

  dataCell: {
    width: '10%',
    fontFamily: 'Open Sans',
  }
});


export default ExportPDF;