import React, { useState, useEffect } from "react";
import { AutoSizer, Column, Table } from "react-virtualized";
import moment from "moment";
import DataToPretty from "../../functions/dataToPretty";
import { Dropdown, Table as SemanticTable } from "semantic-ui-react";

import "./CustomerTable.scss";

const ReadingData = [
  "BloodPressure",
  "Oxygen",
  "HeartRate",
  "Weight",
  "BodyTemperature",
  "Glucose"
];

const CustomerTable = ({ data, startDate, endDate }) => {
  const [rows, setRows] = useState(
    getScopedData(data, startDate, endDate)
  );

  const [filter, setFilter] = useState(ReadingData);

  const [sortBy, setSortBy] = useState('date');
  const [sortDirection, setSortDirection] = useState('DESC');


  useEffect(() => {
    onDropdownChange(
      filter,
      getScopedData(data, startDate, endDate).reverse(),
      setRows,
      setFilter
    );
  }, [data, startDate, endDate]);

  const sortData = (sorting) => {
    let newSortDirection = '';
    if(sortBy !== sorting.sortBy)
      newSortDirection = 'DESC';
    else
      newSortDirection = sortDirection === 'DESC' ? 'ASC' : 'DESC';

    setSortDirection(newSortDirection);

    setSortBy(sorting.sortBy);

    let sortedData = [...rows].sort((a, b) => {
      let sortableA = sorting.sortBy === 'date' ? moment(a[sorting.sortBy]) : (
                      sorting.sortBy === 'BloodPressure' && a[sorting.sortBy] !== null ? Number(a[sorting.sortBy].replace(/\\/g, '')) :
                      Number(a[sorting.sortBy]));

      let sortableB = sorting.sortBy === 'date' ? moment(b[sorting.sortBy]) : (
                      sorting.sortBy === 'BloodPressure' && b[sorting.sortBy] !== null ? Number(b[sorting.sortBy].replace(/\\/g, '')) :
                      Number(b[sorting.sortBy]));

      if(sortableA < sortableB) return newSortDirection === 'ASC' ? -1 : 1;
      if(sortableA > sortableB) return newSortDirection === 'ASC' ? 1 : -1;
      if(sortableA == sortableB) return 0;
    });

    setRows(sortedData);

    // console.log('sorting', sorting);
    // console.log('sortDirection', newSortDirection);
    // console.log('sortBy', sorting.sortBy);
    // console.log('sortedData', sortedData);
    // console.log('____________')
  }

  return (
    <div className="customer-table__container">
      <div className="customer-table__header">
        <h1>Vitals Data</h1>
        <FilterDropdown 
          data={data} 
          setRows={setRows} 
          setFilter={setFilter} 
        />
      </div>
      <div className="customer-table__auto-sizer">
        <AutoSizer>
          {({ height, width }) => (
            <Table
              headerHeight={56}
              height={height}
              rowCount={rows.length}
              rowGetter={({ index }) => rows[index]}
              rowHeight={56}
              sortBy={sortBy}
              sortDirection={sortDirection}
              sort={sortData}
              width={width}
            >
              <Column 
                defaultSortDirection='DESC'
                label="Date" 
                dataKey="date" 
                width={200} 
              />
              <Column
                defaultSortDirection='DESC'
                label="Blood Pressure (mm/Hg)"
                dataKey="BloodPressure"
                width={200}
              />
              <Column 
                defaultSortDirection='DESC'
                label="Oxygen (%)" 
                dataKey="Oxygen" 
                width={200} 
              />
              <Column
                defaultSortDirection='DESC'
                label="Heart Rate (BPM)"
                dataKey="HeartRate"
                width={200}
              />
              <Column 
                defaultSortDirection='DESC'
                label="Weight (lbs)" 
                dataKey="Weight" 
                width={200} 
              />
              <Column
                defaultSortDirection='DESC'
                label="Body Temperature (°F)"
                dataKey="BodyTemperature"
                width={200}
              />
              <Column 
                defaultSortDirection='DESC'
                label="Glucose (mg/dl)" 
                dataKey="Glucose" 
                width={200} 
              />
            </Table>
          )}
        </AutoSizer>
      </div>
    </div>
  );


};

const FilterDropdown = ({ data, setRows, setFilter }) => {
  return (
    <div className="customer-table__filter-dropdown">
      <Dropdown
        fluid
        multiple
        search
        selection
        placeholder='Filter'
        options={ReadingData.map(attribute => ({
          value: attribute,
          text: DataToPretty[attribute]
        }))}
        onChange={(e, { value }) =>
          onDropdownChange(value, data, setRows, setFilter)
        }
      />
    </div>
  );
};

const onDropdownChange = (newValue, data, setRows, setFilter) => {
  setFilter(newValue);

  if (newValue.length === 0) {
    setRows(data);
  } else {
    setRows(
      data.filter(reading => {
        for (let i = 0; i < newValue.length; i++) {
          if (reading[newValue[i]] !== null) return true;
        }
        return false;
      })
    );
  }
};

const getScopedData = (data, startDate, endDate) => {
  return data.filter(
    reading =>
      moment(reading.date).isAfter(moment(startDate).startOf("day")) &&
      moment(reading.date).isBefore(moment(endDate).endOf("day"))
  );
};

export default CustomerTable;
