// const LoginTheme = {
//     button: {"backgroundColor":"#4BCBF3"},
//     formContainer: { "backgroundColor": "#f5f5f5", 
//     "margin": "0" ,
//     "position": "absolute", 
//     "top": "50%", 
//     "left": "50%", 
//     "-ms-transform": translate(-50%, -50%); 
//     transform: translate(-50%, -50%); } 
// }

export const Button = {
    button: {backgroundColor:"#4BCBF3",  borderRadius: "25px"}
   
}

export const FormContainer = {
    formContainer: { "backgroundColor": "#f5f5f5"}
}

export const FormSection = {
    container: { backgroundColor:"#4BCBF3"}
}
