import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import './OfficeDropdown.scss';

const OfficeDropdown = ({ offices, onChange, disabled, defaultValue }) => {
  const options = offices.map((office) => ({ key: office.officeID, value: office.officeName, text: `${office.description}`}));

  return (
    <div className='office-dropdown__container'>
      <Dropdown
        options={options}
        disabled={disabled}
        fluid
        search
        selection
        placeholder="Select Office"

        // defaultValue={options[0].value}
        onChange={(e, { value }) => { 
          onChange(value); 
        }}
      />
    </div>
  );
};

export default OfficeDropdown;